<template>
    <div>
        <div class="web-header">
            <div class="web-logo">
                <el-image class="web-logo-image" :src="logo" />
            </div>
            <div class="web-nav">
                <el-menu router mode="horizontal" text-color="#0087cf" active-text-color="#0087cf" @select="handleSelect">
                    <el-menu-item index="/index">首页</el-menu-item>
                    <el-menu-item index="/about">关于我们</el-menu-item>
                    <el-menu-item index="/news">新闻咨询</el-menu-item>
                    <el-menu-item index="/product">产品中心</el-menu-item>
                    <el-menu-item index="/case">服务案例</el-menu-item>
                    <el-menu-item index="/intention">留言咨询</el-menu-item>
                    <el-menu-item index="/contact">联系我们</el-menu-item>
                </el-menu>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppHeader",
        props:{
            menuOptions:{
                type:Array,
                default:[]
            },
            logo:{
                type: String,
                default: null
            }
        },
        data(){
            return{
                menuList:[]
            }
        },
        created() {
          this.init();
        },
        methods:{
            init(){
                this.menuList = this.menuOptions;
                // console.log(constantRoutes);
                // router.addRoutes({name:"test",path:"/about"});
                // this.routers.map((router) => {
                //
                // })
            },
            // 菜单选择事件
            handleSelect(key, keyPath) {

            }
        }
    }
</script>

<style scoped>

    >>>.el-menu-item{
        font-size: 16px;
    }

    >>>.el-menu.el-menu--horizontal{
        border-bottom: 0;
    }

    .web-nav{
        /*width: 100%;*/
        display: flex;
        align-items: center;
    }

    .web-logo-image{
        width: 400px;
        height: 100px;
    }

    .web-logo{
        display: flex;
        align-items: center;
        margin-right: 2%;
        padding: 10px 0;
    }

    .web-header{
        /*width: 100%;*/
        /*position: fixed;*/
        /*top: 0;*/
        /*z-index: 999;*/
        padding: 0 40px;
        display: flex;
        background: #fff;
        box-shadow: 0 2px 5px rgb(0 0 0 / 5%);
        -webkit-box-shadow: 0 2px 5px rgb(0 0 0 / 5%);
        align-items: center;
        justify-content: center;
    }
</style>