import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

import Layout from '@/layout'
// import {constantRoutes} from "../../../../ruoyi-ui/src/router";

const routes = [
  {
    path: '',
    component: Layout,
    redirect: 'index',
    children: [
      {
        path: 'index',
        component: () => import('@/views/index'),
        name: 'Index',
        meta: { title: '首页', icon: 'dashboard', affix: true }
      }
    ]
  },
  {
    path: '/about',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '',
        component: () => import('@/views/about/index'),
        name: 'AboutIndex',
        meta: { title: '关于我们'}
      }
    ]
  },
  {
    path: '/case',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '',
        component: () => import('@/views/case/index'),
        name: 'CaseIndex',
        meta: { title: '经典案例'}
      }
    ]
  },
  {
    path: '/case/detail',
    component: Layout,
    hidden: true,
    children: [
      {
        path: ':id(\\d+)',
        component: () => import('@/views/case/detail'),
        name: 'NewsDetail',
        meta: { title: '经典案例'}
      }
    ]
  },
  {
    path: '/news',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '',
        component: () => import('@/views/news/index'),
        name: 'NewsIndex',
        meta: { title: '新闻资讯'}
      }
    ]
  },
  {
    path: '/news/detail',
    component: Layout,
    hidden: true,
    children: [
      {
        path: ':id(\\d+)',
        component: () => import('@/views/news/detail'),
        name: 'NewsDetail',
        meta: { title: '新闻资讯'}
      }
    ]
  },
  {
    path: '/product',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '',
        component: () => import('@/views/product/index'),
        name: 'ProductIndex',
        meta: { title: '产品中心'}
      }
    ]
  },
  {
    path: '/product/detail',
    component: Layout,
    hidden: true,
    children: [
      {
        path: ':id(\\d+)',
        component: () => import('@/views/product/detail'),
        name: 'ProductDetail',
        meta: { title: '产品中心'}
      }
    ]
  },
  {
    path: '/contact',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '',
        component: () => import('@/views/contact/index'),
        name: 'ContactIndex',
        meta: { title: '联系我们'}
      }
    ]
  },
  {
    path: '/intention',
    component: Layout,
    hidden: true,
    children: [
      {
        path: '',
        component: () => import('@/views/intention/index'),
        name: 'IntentionIndex',
        meta: { title: '留言咨询'}
      }
    ]
  }
]

// // 防止连续点击多次路由报错
// let routerPush = Router.prototype.push;
// Router.prototype.push = function push(location) {
//   return routerPush.call(this, location).catch(err => err)
// }

export default new Router({
  mode: 'history', // 去掉url中的#
  scrollBehavior: () => ({ y: 0 }),
  routes: routes
})
