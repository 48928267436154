import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Element from 'element-ui'
import './assets/styles/element-variables.scss'
import Cookies from 'js-cookie'
import { parseTime, handleTree } from "@/utils/utils";
import LeftMenu from "@/components/LeftMenu"
import Pagination from "@/components/Pagination";

Vue.config.productionTip = false

Vue.prototype.parseTime = parseTime
Vue.prototype.handleTree = handleTree

Vue.component('LeftMenu', LeftMenu)
Vue.component('Pagination', Pagination)

Vue.use(Element, {
  size: 'medium' // set element-ui default size
})

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
