<template>
    <div style="min-height: 85%">
        <router-view v-if="!$route.meta.link" :key="key" />
    </div>
</template>

<script>
    export default {
        name: 'AppMain',
        computed: {
            key() {
                return this.$route.path
            }
        }
    }
</script>

<style scoped>

</style>