import request from '@/utils/request'
import config from '../utils/config';

export function leftMenuList(code) {
    return request({
        url: '/common/child/menu/'+ config.configId +'/' + code,
        method: 'get'
    })
}

export function websiteInfo() {
    return request({
        url: '/common/website',
        method: 'get'
    })
}