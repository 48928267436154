<template>
    <div style="height: 100%">
        <app-header :menu-options="menuOptions" :logo="info.logo" />
        <app-main />
        <app-floor :icp="info.icp" :company="info.name" />
    </div>
</template>

<script>
    import { websiteInfo } from '@/api/common';
    import { AppMain, AppHeader, AppFloor } from './components'
    export default {
        name: "Layout",
        components: { AppMain, AppHeader, AppFloor },
        data(){
            return {
                menuOptions:[],
                info:{},
            }
        },
        created() {

            this.init();
        },
        methods:{
            init(){
                this.reset();
                websiteInfo().then(response => {
                    // this.menuOptions = response.data.menus;
                    this.info.icp = response.data.icp;
                    this.info.logo = response.data.logo;
                    this.info.ico = response.data.ico;
                    this.info.name = response.data.name;
                })
                console.log(this.info);
            },
            reset(){
                this.menuOptions = [];
                this.info = {
                    icp:null,
                    ico:null,
                    logo:null,
                    name:null
                }
            },
        }
    }
</script>

<style scoped>

</style>