<template>
    <div class="web-floor">
        <div>版权所有：{{company}}</div>
        <div>
            <el-link :underline="false" href="https://beian.miit.gov.cn">备案号：{{icp}}</el-link>
        </div>
    </div>
</template>

<script>
    export default {
        name: "AppFloor",
        props:{
            icp:{
                type: String,
                default: null
            },
            company:{
                type: String,
                default: null
            }
        },
        created() {}
    }
</script>

<style scoped>

    .web-floor div{
        margin-right: 2%;
    }

    .web-floor{
        /*position: fixed;*/
        /*bottom: 0;*/
        /*width: 100%;*/
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px 30px;
        font-size: 12px;
        color: #808080;
        /*position: absolute;*/
        /*bottom: 0;*/
        /*left: 0;*/
    }
</style>