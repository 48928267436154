<template>
    <div class="left-menu">
        <div v-if="leftTree === 'tree'">
            <div v-if="topTitle === '0'">
                <div id="n_content_left_t" class="n_content_left_t">
                            <span class="n_content_left_t1">
                                <span id="ShoveWebControl_Text1">{{title}}</span>
                            </span><span class="n_content_left_t2">

                                <span name="_nleft_n_name1">{{titleDesc}}</span>

                            </span>

                </div>
            </div>
            <div class="left-menu-title" v-else>{{title}}</div>
            <div style="margin-top: 5%">
                <el-tree
                        :empty-text="'暂无分类'"
                        :data="leftMenuOptions"
                        :props="defaultProps"
                        @node-click="handleNodeClick">
                <div :class="{'custom-tree-node':titleCenter}" slot-scope="{ node, data }">
                    <div class="tree-title">{{ data.name }}</div>
<!--                    <el-divider />-->
                </div>
                </el-tree>
            </div>

        </div>
        <div v-else>
            <div class="left-menu-title">{{title}}</div>
            <div class="left-menu-item" @click="handleClick(item)"  v-for="item in leftMenuOptions">
                <div class="left-menu-card">
                    <div>{{item.name}}</div>
                    <div>+</div>
                </div>
                <el-collapse-transition>
                    <div v-show="item.showChild" >
                        <div class="left-menu-child-card" v-for="val in item.children">
                            <div>{{val.name}}</div>
                        </div>
                    </div>
                </el-collapse-transition>
            </div>
        </div>
    </div>
</template>

<script>
    import { leftMenuList } from "@/api/common";
    export default {
        name: "LeftMenu",
        props: {
            title:{
                type:String,
                default: null
            },
            code:{
                type:String,
                default: null
            },
            group: {
                type: [Number, String],
                default: null
            },
            leftTree: {
                type:  String,
                default: 'tree'
            },
            titleCenter: {
                type:  Boolean,
                default: true
            },
            topTitle: {
                type:  String,
                default: '0'
            },
            titleDesc:[String],
            // menuList: {
            //     type:Array,
            //     default:[]
            // }
        },
        data(){
            return{
                leftMenuOptions:[],
                defaultProps: {
                    children: 'children',
                    label: 'name'
                }
            }
        },
        watch: {
            code:{
                handler(val) {
                    this.getLeftMenu();
                },
                deep: true,
                immediate: true
            }
        },
        created() {
            this.leftMenuOptions = this.menuList;
        },
        methods:{
            getLeftMenu(){
                this.leftMenuOptions = [];
                if(this.code != null){
                    leftMenuList(this.code).then(response => {
                        this.leftMenuOptions = this.handleTree(response.data);
                        if(this.leftMenuOptions.length > 0){
                            this.leftMenuOptions.forEach(m => {
                                m['showChild'] = false;
                            })
                        }
                    })
                }
            },
            handleNodeClick(row){
                this.handleClick(row);
                // console.log(row);
            },
            handleClick(row){
                if(row.children != null && row.children.length > 0){
                    for(var i =0; i < this.leftMenuOptions.length; i++){
                        if(this.leftMenuOptions[i].id !== row.id && this.leftMenuOptions[i].showChild){
                            this.leftMenuOptions[i].showChild = false;
                            this.$set(this.leftMenuOptions,i,this.leftMenuOptions[i]);
                        }
                        if(this.leftMenuOptions[i].id === row.id){
                            this.leftMenuOptions[i].showChild ? this.leftMenuOptions[i].showChild = false :  this.leftMenuOptions[i].showChild = true;
                            this.$set(this.leftMenuOptions,i,this.leftMenuOptions[i]);
                        }
                    }
                    if(row.code == null || row.code.length == 0 || row.code == ''){
                        this.$emit('input', row.id);
                    }else{
                        this.$emit('input', row.code);
                    }
                }else{
                    if(row.code == null || row.code.length == 0 || row.code == ''){
                        this.$emit('input', row.id);
                    }else{
                        this.$emit('input', row.code);
                    }
                }
                this.$emit('handleClick');
            }
        }
    }
</script>

<style scoped>

    .n_content_left_t {
        width: 220px;
        height: 40px;
        text-align: left;
        line-height: 40px;
    }

    .n_content_left_t1 {
        font-size: 24px;
        color: #333333;
        border-bottom: 3px solid #0085c3;
        padding-bottom: 3px;
    }

    .n_content_left_t2 {
        font-size: 18px;
        font-weight: normal;
        color: #999999;
        padding-left: 5px;
    }

    >>>.el-icon-caret-right{
        display: none;
    }

    .custom-tree-node {
        display: flex;
        width: 100%;
        text-align: left;
        padding-left: 20px;
        /*align-items: center;*/
        /*justify-content: center;*/
    }

    .active{
        border-left: 1px #0067B3 solid;
    }

    .left-menu-card:hover{
        color: #FFF;
        background: #0067B3;
    }

    .left-menu-child-card{
        border: 1px #bbb solid;
        border-top: 0;
        /*display: flex;*/
        /*justify-content: space-between;*/
        padding: 15px 25px;
    }

    .left-menu-card{
        border: 1px #bbb solid;
        display: flex;
        justify-content: space-between;
        padding: 15px 25px;
    }

    .left-menu-item{
        margin: 7px 0;
    }

    .left-menu{
        display: block;
        color: #666;
        font-size: 16px;
    }

    .tree-title{
        /*font-size: 22px;*/
        /*font-weight: 700;*/
        /*font-family: SimSun;*/
        white-space:normal;
    }

    .left-menu-title{
        color: #FFF;
        font-size: 24px;
        font-weight: 700;
        background: #0067B3;
        text-align: center;
        padding: 20px 0;
        letter-spacing: 8px
    }

    /deep/ .el-tree-node__content{
        height: 50px;
    }
</style>